import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";
import axios from "axios";
import Spinner from "../Spinner/Spinner";
const sha1 = require("sha1");

async function loginUser(credentials) {
  console.log(credentials);
  return fetch(
    "https://" + process.env.REACT_APP_SERVER + "/auth/sign-in-admin",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    }
  )
    .then((data) => data.json())
    .then((d) => {
      localStorage.setItem(
        "login",
        JSON.stringify({
          token: d.token,
        })
      );

      //   return <App />
      window.location.reload();

      // console.log(d);
    });
}

function Login(credentials) {
  const [username, setusername] = useState();
  const [pass, setpass] = useState();
  const [spinner, setspinner] = useState(false);
  const handleSubmit = async (e) => {
    // e.preventDefault();
    let data = {
      admin_eid: username,
      password: pass,
    };
    await loginUser(data);
    setspinner(false);
  };

  return (
    <div className="login">
      {spinner ? <Spinner></Spinner> : <></>}
      <Card className="loginform">
        <Card.Body>
          <Card.Title>Sign In</Card.Title>
          <hr></hr>
          <Card.Text>
            <div className="row mb-3 mt-3">
              <div className="col-md-2 mb-2">
                <img src="./email.svg" alt="email" height={42} width={50} />
              </div>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  id="InputEmail"
                  placeholder="Your Employee ID"
                  onChange={(e) => setusername(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 mb-2">
                <img src="./lock.svg" alt="lock" height={35} width={50} />
              </div>
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  id="InputPass"
                  placeholder="Your Password"
                  onChange={(e) => setpass(sha1(e.target.value))}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setspinner(true);
                      handleSubmit();
                    }
                  }}
                />
              </div>
            </div>
          </Card.Text>
          <Button
            type="button"
            variant="secondary"
            className="mb-3 mt-3"
            onClick={() => {
              handleSubmit();
              setspinner(true);
            }}
          >
            Sign In
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Login;
