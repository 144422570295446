import React, { useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/master.css";
import TicketsAll from "./TicketsAll";
import TicketsByOrg from "./TicketsByOrg";

export default function Tickets({ projectData, orgData, ticketData }) {
  const [variable, setvar] = useState(false);

  return (
    <>
      <div class="container">
        <div class="centerHead">
          <h3>Tickets</h3>
          <hr></hr>
        </div>
        <div class="container">&nbsp;</div>
        <div class="row">
          <div class="col-md-3">
            <h6 class="centerHead">
              <u>View Tickets</u>
            </h6>
            <Button onClick={() => setvar(true)}>All Tickets</Button>
            &nbsp;
            <Button onClick={() => setvar(false)}>
              Tickets By Organisation
            </Button>
          </div>

          <div class="col-md-1">
            <div class="vll"></div>
          </div>

          <div class="col-md-7">
            {/* <TicketsAll></TicketsAll> */}
            {variable ? (
              <TicketsAll
                projectData={projectData}
                orgData={orgData}
                ticketData={ticketData}
              />
            ) : (
              <TicketsByOrg
                projectData={projectData}
                orgData={orgData}
                ticketData={ticketData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
