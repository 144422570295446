import React, { useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../css/master.css";
import Spinner from "../Spinner/Spinner";
const sha1 = require("sha1");

export default function UserCreation({ orgData, userData }) {
  var token = JSON.parse(localStorage.getItem("login"));

  const [disable, setDisable] = useState(true);
  const [dataofuser, setdataofuser] = useState([]);
  const [newuser, setnewuser] = useState({});
  const [passwordofuser, setpasswordofuser] = useState([]);
  const [organ, setorg] = useState("");
  const [val, setVal] = useState("");
  const [valNew, setValNew] = useState("");
  const [spinner, setspinner] = useState(false);

  const createUser = async () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (newuser.user_phone.length != 10) {
      window.alert("Phone Number is of 10 Digits!");
    } else if (!pattern.test(newuser.user_email)) {
      window.alert("Email Incorrect!");
    } else {
      let url =
        "https://" + process.env.REACT_APP_SERVER + "/adminpost/create-user";
      await axios
        .post(url, newuser, {
          headers: {
            "auth-token": token.token,
          },
        })
        .then((r) => {
          window.alert(
            r.status == 200
              ? "User Created Successfully"
              : "Error Creating User"
          );
        });
      setspinner(false);
      window.location.reload();
    }
  };

  const updateUser = async () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (dataofuser.user_phone.length != 10) {
      window.alert("Phone Number is of 10 Digits!");
    } else if (!pattern.test(dataofuser.user_email)) {
      window.alert("Email Incorrect!");
    } else {
      let datatoupdate = {
        user_id: dataofuser.user_id,
        user_name: dataofuser.user_name,
        user_email: dataofuser.user_email,
        user_phone: dataofuser.user_phone,
      };
      let url =
        "https://" + process.env.REACT_APP_SERVER + "/adminput/edit-user";
      await axios
        .put(url, datatoupdate, {
          headers: {
            "auth-token": token.token,
          },
        })
        .then((r) => {
          window.alert(
            r.status == 200
              ? "User Updated Successfully"
              : "Error Updating User"
          );
        });
      setspinner(false);
      window.location.reload();
    }
  };

  const updateUserPassword = async () => {
    let url =
      "https://" +
      process.env.REACT_APP_SERVER +
      "/adminput/edit-user-password";
    await axios
      .put(url, passwordofuser, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Password Updated Successfully"
            : "Error Updating Password"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const deleteUser = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/admindelete/delete-user";
    await axios
      .delete(url, {
        headers: {
          "auth-token": token.token,
        },
        data: dataofuser,
      })
      .then((r) => {
        window.alert(
          r.status == 200 ? "User Deleted Successfully" : "Error Deleting User"
        );
      });
    setspinner(false);
    window.location.replace("/uc");
  };

  const getOrgName = async (datum) => {
    let theOrg = orgData.find((r) => r.org_id === datum).org_name;
    setorg(theOrg);
  };

  return (
    <>
      <div class="container">
        {spinner ? <Spinner></Spinner> : <></>}
        <div class="centerHead">
          <h3>User Creation</h3>
          <hr></hr>
        </div>
        <div class="container">&nbsp;</div>
        <div class="row">
          <div class="col-md-3">
            <h6 class="centerHead">
              <u>List of Users</u>
            </h6>
            <ListGroup as="ul">
              {userData.map((user, index) => {
                return (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className={"pointer"}
                    active={
                      user.user_name === dataofuser.user_name ? true : false
                    }
                    onClick={() => {
                      setdataofuser(user);
                      getOrgName(user.org_id);
                      setpasswordofuser(user);
                    }}
                  >
                    {user.user_name}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>

          <div class="col-md-1">
            <div class="vll"></div>
          </div>

          <div class="col-md-3">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofuser.user_name}
                  disabled={disable}
                  placeholder="Enter Name"
                  onChange={(e) => {
                    dataofuser.user_name = e.target.value;
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  pattern="[0-9]*"
                  // value={val}
                  defaultValue={dataofuser.user_phone}
                  disabled={disable}
                  placeholder="Enter Phone"
                  onChange={(e) => {
                    dataofuser.user_phone = e.target.value;
                    setVal((v) =>
                      e.target.validity.valid ? e.target.value : v
                    );
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  defaultValue={dataofuser.user_email}
                  disabled={disable}
                  placeholder="Enter Email"
                  onChange={(e) => {
                    dataofuser.user_email = e.target.value;
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  defaultValue={dataofuser.user_password}
                  disabled={disable}
                  placeholder="Password"
                  onChange={(e) => {
                    passwordofuser.user_password = sha1(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>UID</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofuser.user_id}
                  disabled={true}
                  placeholder="UID"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Organisation</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={organ}
                  disabled={true}
                  placeholder="Organisation Name"
                />
              </Form.Group>
            </Form>
            <div className="row" style={{ textAlign: "center" }}>
              <div className="col-md-6">
                <Button
                  variant="primary"
                  onClick={() => setDisable(!disable)}
                  type="Edit"
                  style={{ margin: "4px" }}
                >
                  {disable ? <>Edit</> : <>Cancel</>}
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  variant="primary"
                  disabled={disable}
                  type="button"
                  onClick={() => {
                    updateUser();
                    setspinner(true);
                  }}
                  style={{ margin: "4px" }}
                >
                  Update
                </Button>
              </div>
            </div>
            <div className="row" style={{ textAlign: "center" }}>
              <div className="col-md-6">
                <Button
                  variant="primary"
                  disabled={disable}
                  type="button"
                  onClick={() => {
                    updateUserPassword();
                    setspinner(true);
                  }}
                  style={{ margin: "4px" }}
                >
                  Update Password
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  variant="danger"
                  disabled={disable}
                  type="delete"
                  onClick={() => {
                    deleteUser();
                    setspinner(true);
                  }}
                  style={{ margin: "4px" }}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>

          <div class="col-md-1">
            <div class="vlr"></div>
          </div>

          <div class="col-md-4">
            <h6 class="centerHead">
              <u>create User</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => (newuser.user_name = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) =>
                    (newuser.user_password = sha1(e.target.value))
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  pattern="[0-9]*"
                  value={valNew}
                  placeholder="Phone"
                  onChange={(e) => {
                    newuser.user_phone = e.target.value;
                    setValNew((v) =>
                      e.target.validity.valid ? e.target.value : v
                    );
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  onChange={(e) => (newuser.user_email = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>UID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="UID"
                  onChange={(e) => (newuser.user_id = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Select Organisation</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => (newuser.org_name = e.target.value)}
                >
                  <option value="selectorg">Select Organisation</option>
                  {orgData.map((org, index) => {
                    return (
                      <option key={index} value={org.org_name}>
                        {org.org_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <div className="row text-center">
                <div className="col">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      createUser();
                      setspinner(true);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
