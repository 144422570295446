import React, { useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../css/master.css";

export default function TicketsAll({ projectData, orgData, ticketData }) {
  var token = JSON.parse(localStorage.getItem("login"));

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [disable, setDisable] = useState(true);
  const [spinner, setspinner] = useState(false);

  const [dataofticket, setdataofticket] = useState({});
  const [projectname, setprojectname] = useState("");
  const [orgname, setorgname] = useState("");

  const updateTicket = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminput/update-ticket";
    await axios
      .put(url, dataofticket, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Ticket Updated Successfully"
            : "Error Updating Ticket"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const getOrgName = async (datum) => {
    let theOrg = orgData.find((r) => r.org_id === datum).org_name;
    setorgname(theOrg);
  };

  const getProjectName = async (datum) => {
    let theProject = projectData.find((r) => r.project_id === datum);
    getOrgName(theProject.org_id);
    setprojectname(theProject.project_name);
  };

  const setTicketStatus = () => {
    setIsSwitchOn(!isSwitchOn);
    dataofticket.ticket_open = !isSwitchOn == true ? 1 : 0;
  };
  return (
    <>
      <div className="container">
        {spinner ? <Spinner></Spinner> : <></>}
        <div className="container">&nbsp;</div>
        <div className="row">
          <div className="col-md-3">
            <h6 className="centerHead">
              <u>List of Tickets</u>
            </h6>
            <ListGroup as="ul">
              {ticketData?.map((ticket, index) => {
                return (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className={"pointer"}
                    active={
                      ticket.ticket_id === dataofticket.ticket_id ? true : false
                    }
                    onClick={() => {
                      ticket.ticket_open == 1
                        ? setIsSwitchOn(true)
                        : setIsSwitchOn(false);
                      setdataofticket(ticket);
                      getProjectName(ticket.project_id);
                    }}
                  >
                    {ticket.ticket_id}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>

          <div className="col-md-1">
            <div className="vlr"></div>
          </div>

          <div className="col-md-6">
            <h6 className="centerHead">
              <u>Ticket Details</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Organisation Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Organisation Name"
                  disabled={true}
                  defaultValue={orgname}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Project Name"
                  disabled={true}
                  defaultValue={projectname}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>TID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Ticket Incident ID"
                  disabled={true}
                  defaultValue={dataofticket.ticket_id}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ticket Description"
                  disabled={true}
                  defaultValue={dataofticket.ticket_description}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ticket Status/Resolution Message"
                  disabled={disable}
                  defaultValue={dataofticket.ticket_comments}
                  onChange={(e) =>
                    (dataofticket.ticket_comments = e.target.value)
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Select Ticket Type</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  disabled={disable}
                  value={dataofticket.ticket_type}
                  onChange={(e) => {
                    let nd = {};
                    for (let x in dataofticket) {
                      if (x == "ticket_type") {
                        nd["ticket_type"] = e.target.value;
                      } else {
                        nd[x] = dataofticket[x];
                      }
                    }
                    setdataofticket(nd);
                  }}
                >
                  <option>Select Ticket Type</option>
                  <option value="Support">Support</option>
                  <option value="Feedback">Feedback</option>
                  <option value="Complaint">Complaint</option>
                  <option value="Dispute">Dispute</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>

              <Form.Switch
                onChange={() => setTicketStatus()}
                id="custom-switch"
                label="Ticket is open"
                checked={isSwitchOn}
                disabled={disable}
              />
            </Form>
            <div className="text-center">
              <Button
                variant="primary"
                onClick={() => setDisable(!disable)}
                type="Edit"
              >
                {disable ? <>Edit</> : <>Cancel</>}
              </Button>
              &nbsp;
              <Button
                variant="primary"
                disabled={disable}
                type="button"
                onClick={() => {
                  updateTicket();
                  setspinner(true);
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
