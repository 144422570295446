import React, { useState, useEffect } from "react";
import { Button, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../css/master.css";
import Spinner from "../Spinner/Spinner";

export default function ProjectCreation({ projectData, orgData }) {
  var token = JSON.parse(localStorage.getItem("login"));

  const [disable, setDisable] = useState(true);
  const [dataofproject, setdataofproject] = useState([]);
  const [newproject, setnewproject] = useState({});
  const [organ, setorg] = useState("");
  const [spinner, setspinner] = useState(false);

  useEffect(() => {}, [dataofproject]);

  const updateProject = async () => {
    let datatoupdate = {
      project_id: dataofproject.project_id,
      project_name: dataofproject.project_name,
      progress: dataofproject.project_progress,
      subscriptions: dataofproject.active_subscriptions,
      bill_preference: dataofproject.bill_preference,
    };
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminput/edit-project";
    await axios
      .put(url, datatoupdate, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Project Updated Successfully"
            : "Error Updating Project"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const createProject = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminpost/create-project";
    await axios
      .post(url, newproject, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Project Created Successfully"
            : "Error Creating Project"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const deleteProject = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/admindelete/delete-project";
    await axios
      .delete(url, {
        headers: {
          "auth-token": token.token,
        },
        data: dataofproject,
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Project Deleted Successfully"
            : "Error Deleting Project"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const getOrgName = async (datum) => {
    let theOrg = orgData.find((r) => r.org_id === datum).org_name;
    setorg(theOrg);
  };

  return (
    <>
      <div className="container">
        {spinner ? <Spinner></Spinner> : <></>}
        <div className="centerHead">
          <h3>Project Creation</h3>
          <hr></hr>
        </div>
        <div className="container">&nbsp;</div>
        <div className="row">
          <div className="col-md-3">
            <h6 className="centerHead">
              <u>List of Projects</u>
            </h6>
            <ListGroup as="ul">
              {projectData.map((project, index) => {
                return (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className={"pointer"}
                    active={
                      project.project_name === dataofproject.project_name
                        ? true
                        : false
                    }
                    onClick={() => {
                      setdataofproject(project);
                      getOrgName(project.org_id);
                      console.log(project);
                    }}
                  >
                    {project.project_name}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>

          <div className="col-md-1">
            <div className="vll"></div>
          </div>

          <div className="col-md-3">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Organisation</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={organ}
                  placeholder="Organisation Name"
                  disabled={true}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>PID</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofproject.project_id}
                  placeholder="PID"
                  disabled={true}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofproject.project_name}
                  placeholder="Project Name"
                  disabled={disable}
                  onChange={(e) => {
                    dataofproject.project_name = e.target.value;
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Active Subscriptions</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofproject.active_subscriptions}
                  placeholder="Active Subscriptions (sub A, sub B, NA (Case sensitive))."
                  disabled={disable}
                  onChange={(e) => {
                    dataofproject.active_subscriptions = e.target.value;
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Progress</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofproject.project_progress}
                  placeholder="Progress"
                  disabled={disable}
                  onChange={(e) => {
                    dataofproject.project_progress = e.target.value;
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Billing Preference</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  disabled={disable}
                  value={dataofproject.bill_preference}
                  onChange={(e) => {
                    let nd = {};
                    for (let x in dataofproject) {
                      if (x == "bill_preference") {
                        nd["bill_preference"] = e.target.value;
                      } else {
                        nd[x] = dataofproject[x];
                      }
                    }
                    setdataofproject(nd);
                  }}
                >
                  <option>Select Bill Preference</option>
                  <option value="1">Monthly</option>
                  <option value="2">Quarterly</option>
                  <option value="3">Bi-Annually</option>
                  <option value="4">Annually</option>
                </Form.Select>
              </Form.Group>
            </Form>
            <Button
              variant="primary"
              onClick={() => setDisable(!disable)}
              type="Edit"
            >
              {disable ? <>Edit</> : <>Cancel</>}
            </Button>
            &nbsp;
            <Button
              variant="primary"
              disabled={disable}
              type="button"
              onClick={() => {
                updateProject();
                setspinner(true);
              }}
            >
              Update
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="danger"
              disabled={disable}
              type="delete"
              onClick={() => {
                deleteProject();
                setspinner(true);
              }}
            >
              Delete
            </Button>
          </div>

          <div className="col-md-1">
            <div className="vlr"></div>
          </div>

          <div className="col-md-4">
            <h6 className="centerHead">
              <u>create Project</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Select Organisation</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => (newproject.org_name = e.target.value)}
                >
                  <option>Select Organisation</option>
                  {orgData.map((org, index) => {
                    return (
                      <option key={index} value={org.org_name}>
                        {org.org_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>PID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="PID"
                  onChange={(e) => (newproject.project_id = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Project Name"
                  onChange={(e) => (newproject.project_name = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Active Subscriptions</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Active Subscriptions (sub A, sub B,  NA (Case sensitive))"
                  onChange={(e) => (newproject.subscriptions = e.target.value)}
                />
              </Form.Group>
              <div className="row text-center">
                <div className="col">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      createProject();
                      setspinner(true);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
