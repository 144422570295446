import React, { useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../css/master.css";

export default function TicketsByOrg({ projectData, orgData, ticketData }) {
  var token = JSON.parse(localStorage.getItem("login"));

  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [disable, setDisable] = useState(true);
  const [disableTickets, setDisableTickets] = useState(true);
  const [disableProjects, setDisableProjects] = useState(true);
  const [spinner, setspinner] = useState(false);

  const [orgid, setorgid] = useState("");
  const [projectid, setprojectid] = useState("");
  const [ticketdetails, setticketdetails] = useState({});
  const [projectDataByOrg, setProjectDataByOrg] = useState([]);
  const [ticketDataByProject, setTicketDataByProject] = useState([]);

  const updateTicket = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminput/update-ticket";
    await axios
      .put(url, ticketdetails, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Ticket Updated Successfully"
            : "Error Updating Ticket"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const getOrgId = async (datum) => {
    let theOrg = orgData.find((r) => r.org_name === datum).org_id;
    setorgid(theOrg);
    findProjectsByOrg(theOrg);
  };

  const getProjectId = async (datum) => {
    let theProject = projectDataByOrg.find(
      (r) => r.project_name === datum
    ).project_id;
    setprojectid(theProject);
    findTicketsByProject(theProject);
  };

  const findProjectsByOrg = async (datum) => {
    let projects = projectData.filter((r) => r.org_id === datum);
    setProjectDataByOrg(projects);
  };

  const findTicketsByProject = async (datum) => {
    let tickets = ticketData.filter((r) => r.project_id === datum);
    setTicketDataByProject(tickets);
    // console.log(tickets);
  };

  const setticket = async (datum) => {
    let theTicket = ticketDataByProject.find((r) => r.ticket_id === datum);
    if (theTicket) {
      setticketdetails(theTicket);
      theTicket.ticket_open == 1 ? setIsSwitchOn(true) : setIsSwitchOn(false);
    }
    // console.log(theTicket);
  };

  const setTicketStatus = () => {
    setIsSwitchOn(!isSwitchOn);
    ticketdetails.ticket_open = !isSwitchOn == true ? 1 : 0;
  };
  return (
    <>
      <div class="container">
        {spinner ? <Spinner></Spinner> : <></>}
        <div class="container">&nbsp;</div>
        <div class="row">
          <div class="col-md-3">
            <h6 class="centerHead">
              <u>Select Ticket</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Select Organisation</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    getOrgId(e.target.value);
                    e.target.value === "selectorg"
                      ? setDisableProjects(true)
                      : setDisableProjects(false);
                  }}
                >
                  <option value="selectorg">Select Organisation</option>
                  {orgData.map((org, index) => {
                    return (
                      <option key={index} value={org.org_name}>
                        {org.org_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Select Project</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  disabled={disableProjects}
                  onChange={(e) => {
                    getProjectId(e.target.value);
                    e.target.value === "selectproj"
                      ? setDisableTickets(true)
                      : setDisableTickets(false);
                  }}
                >
                  <option value="selectproj">Select Project</option>
                  {projectDataByOrg.map((project, index) => {
                    return (
                      <option key={index} value={project.project_name}>
                        {project.project_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Select Ticket Incident ID</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  disabled={disableTickets}
                  onChange={(e) => {
                    setticket(e.target.value);
                  }}
                >
                  <option>Select Ticket Incident ID</option>
                  {ticketDataByProject.map((ticket, index) => {
                    return (
                      <option key={index} value={ticket.ticket_id}>
                        {ticket.ticket_id}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Form>
          </div>

          <div class="col-md-1">
            <div class="vlr"></div>
          </div>

          <div class="col-md-6">
            <h6 class="centerHead">
              <u>Ticket Details</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>TID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Ticket Incident ID"
                  disabled={true}
                  defaultValue={ticketdetails.ticket_id}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ticket Description"
                  disabled={true}
                  defaultValue={ticketdetails.ticket_description}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ticket Status/Resolution Message"
                  disabled={disable}
                  defaultValue={ticketdetails.ticket_comments}
                  onChange={(e) =>
                    (ticketdetails.ticket_comments = e.target.value)
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Select Ticket Type</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  disabled={disable}
                  value={ticketdetails.ticket_type}
                  onChange={(e) => {
                    let nd = {};
                    for (let x in ticketdetails) {
                      if (x == "ticket_type") {
                        nd["ticket_type"] = e.target.value;
                      } else {
                        nd[x] = ticketdetails[x];
                      }
                    }
                    setticketdetails(nd);
                  }}
                >
                  <option>Select Ticket Type</option>
                  <option value="Support">Support</option>
                  <option value="Feedback">Feedback</option>
                  <option value="Complaint">Complaint</option>
                  <option value="Dispute">Dispute</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>

              <Form.Switch
                onChange={() => setTicketStatus()}
                id="custom-switch"
                label="Ticket is open"
                checked={isSwitchOn}
                disabled={disable}
              />
            </Form>
            <div className="text-center">
              <Button
                variant="primary"
                onClick={() => setDisable(!disable)}
                type="Edit"
              >
                {disable ? <>Edit</> : <>Cancel</>}
              </Button>
              &nbsp;
              <Button
                variant="primary"
                disabled={disable}
                type="button"
                onClick={() => {
                  updateTicket();
                  setspinner(true);
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
