import React from "react";
import { Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";

export default function CardView() {
  const navigate = useNavigate();

  return (
    <>
      <div class="container text-center">
        <div class="row">
          <div class="col-md-3">
            <Card style={{ width: "15rem", height: "12rem" }}>
              <Card.Body>
                <Card.Title>User Details</Card.Title>
                <Card.Text>
                  View/Update user details or Create a new client user.
                </Card.Text>
                <Button variant="primary" onClick={() => navigate("/uc")}>
                  Create
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div class="col-md-3">
            <Card style={{ width: "15rem", height: "12rem" }}>
              <Card.Body>
                <Card.Title>Project Details</Card.Title>
                <Card.Text>View/Create/Edit Project Info.</Card.Text>
                <Button variant="primary" onClick={() => navigate("/pc")}>
                  Create
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div class="col-md-3">
            <Card style={{ width: "15rem", height: "12rem" }}>
              <Card.Body>
                <Card.Title>Bills &amp; Invoice</Card.Title>
                <Card.Text>View/Update Billing Details.</Card.Text>
                <Button variant="primary" onClick={() => navigate("/bills")}>
                  View
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div class="col-md-3">
            <Card style={{ width: "15rem", height: "12rem" }}>
              <Card.Body>
                <Card.Title>Ticket Details</Card.Title>
                <Card.Text>View/Update Ticket Details</Card.Text>
                <Button variant="primary" onClick={() => navigate("/tickets")}>
                  View
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
