import React, { useState, useEffect } from "react";
import { Button, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../css/master.css";
import Spinner from "../Spinner/Spinner";
const sha1 = require("sha1");

export default function AdminCreation({ adminData }) {
  var token = JSON.parse(localStorage.getItem("login"));

  const [disable, setDisable] = useState(true);
  const [dataofadmin, setdataofadmin] = useState([]);
  const [passwordofadmin, setpasswordofadmin] = useState([]);
  const [newadmin, setnewadmin] = useState({});
  const [spinner, setspinner] = useState(false);

  const updateAdmin = async () => {
    let datatoupdate = {
      admin_id: dataofadmin.admin_id,
      admin_name: dataofadmin.admin_name,
      designation: dataofadmin.admin_designation,
      department: dataofadmin.admin_department,
    };
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminput/edit-admin";
    await axios
      .put(url, datatoupdate, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Admin Updated Successfully"
            : "Error Updating Admin"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const createAdmin = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminpost/create-admin";
    await axios
      .post(url, newadmin, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Admin Created Successfully"
            : "Error Creating Admin"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const updateAdminPassword = async () => {
    let url =
      "https://" +
      process.env.REACT_APP_SERVER +
      "/adminput/edit-admin-password";
    await axios
      .put(url, passwordofadmin, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Password Updated Successfully"
            : "Error Updating Password"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const deleteAdmin = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/admindelete/delete-admin";
    await axios
      .delete(url, {
        headers: {
          "auth-token": token.token,
        },
        data: dataofadmin,
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Admin Deleted Successfully"
            : "Error Deleting Admin"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  return (
    <>
      <div class="container">
        {spinner ? <Spinner></Spinner> : <></>}
        <div class="centerHead">
          <h3>Admin Creation</h3>
          <hr></hr>
        </div>
        <div class="container">&nbsp;</div>
        <div class="row">
          <div class="col-md-3">
            <h6 class="centerHead">
              <u>List of Admins</u>
            </h6>
            <ListGroup as="ul">
              {adminData.map((admin, index) => {
                return (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className={"pointer"}
                    active={
                      admin.admin_name === dataofadmin.admin_name ? true : false
                    }
                    onClick={() => {
                      setdataofadmin(admin);
                      setpasswordofadmin(admin);
                    }}
                  >
                    {admin.admin_name}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>

          <div class="col-md-1">
            <div class="vll"></div>
          </div>

          <div class="col-md-3">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofadmin.admin_name}
                  disabled={disable}
                  placeholder="Enter Name"
                  onChange={(e) => {
                    dataofadmin.admin_name = e.target.value;
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>EID</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofadmin.admin_eid}
                  disabled={true}
                  placeholder="Enter EID"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Department</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofadmin.admin_department}
                  disabled={disable}
                  placeholder="Enter Department"
                  onChange={(e) => {
                    dataofadmin.admin_department = e.target.value;
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataofadmin.admin_designation}
                  disabled={disable}
                  placeholder="Enter Designation"
                  onChange={(e) => {
                    dataofadmin.admin_designation = e.target.value;
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  defaultValue={dataofadmin.admin_password}
                  disabled={disable}
                  placeholder="Password"
                  onChange={(e) => {
                    passwordofadmin.admin_password = sha1(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
            <div className="text-center row">
              <div className="col-md-6">
                <Button
                  variant="primary"
                  type="Edit"
                  onClick={() => setDisable(!disable)}
                  style={{ margin: "4px" }}
                >
                  {disable ? <>Edit</> : <>Cancel</>}
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  variant="primary"
                  disabled={disable}
                  type="button"
                  onClick={() => {
                    updateAdmin();
                    setspinner(true);
                  }}
                  style={{ margin: "4px" }}
                >
                  Update
                </Button>
              </div>
            </div>
            <div className="text-center row">
              <div className="col-md-6">
                <Button
                  variant="primary"
                  disabled={disable}
                  type="button"
                  onClick={() => {
                    updateAdminPassword();
                    setspinner(true);
                  }}
                  style={{ margin: "4px" }}
                >
                  Update Password
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  variant="danger"
                  disabled={disable}
                  type="delete"
                  onClick={() => {
                    deleteAdmin();
                    setspinner(true);
                  }}
                  style={{ margin: "4px" }}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>

          <div class="col-md-1">
            <div class="vlr"></div>
          </div>

          <div class="col-md-4">
            <h6 class="centerHead">
              <u>create Admin</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => (newadmin.admin_name = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label>EID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="EID"
                  onChange={(e) => (newadmin.admin_eid = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Department</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Department"
                  onChange={(e) => (newadmin.admin_department = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Designation"
                  onChange={(e) =>
                    (newadmin.admin_designation = e.target.value)
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) =>
                    (newadmin.admin_password = sha1(e.target.value))
                  }
                />
              </Form.Group>
              <div className="row text-center">
                <div className="col">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      createAdmin();
                      setspinner(true);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
