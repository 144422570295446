import React from "react";
import "./Navbar.css";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";

const Navbar = () => {
  const navigate = useNavigate();

  function signout() {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  }

  return (
    <nav>
      <div className="logo">
        <img src="./bklogo.png" alt="logo" />
      </div>
      <div style={{ alignSelf: "center" }}>
        <Button variant="primary" onClick={() => navigate("/ac")}>
          Admin User Details
        </Button>
      </div>
      <div style={{ alignSelf: "center" }}>
        <Button variant="primary" onClick={() => navigate("/oc")}>
          Client Org Details
        </Button>
      </div>
      <div style={{ alignSelf: "center" }}>
        <Button variant="danger" type="button" onClick={() => signout()}>
          Log Out
        </Button>
      </div>
    </nav>
  );
};

export default Navbar;
