import React, { useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../css/master.css";
import Spinner from "../Spinner/Spinner";

export default function OrgCreation({ orgData }) {
  var token = JSON.parse(localStorage.getItem("login"));

  const [disable, setDisable] = useState(true);
  const [dataoforg, setdataoforg] = useState([]);
  const [neworg, setneworg] = useState({});
  const [spinner, setspinner] = useState(false);

  const updateOrg = async () => {
    let datatoupdate = {
      org_id: dataoforg.org_id,
      org_name: dataoforg.org_name,
    };
    let url = "https://" + process.env.REACT_APP_SERVER + "/adminput/edit-org";
    await axios
      .put(url, datatoupdate, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Organisation Updated Successfully"
            : "Error Updating Organisation"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const createOrg = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminpost/create-org";
    await axios
      .post(url, neworg, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Organisation Created Successfully"
            : "Error Creating Organisation"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const deleteOrg = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/admindelete/delete-org";
    await axios
      .delete(url, {
        headers: {
          "auth-token": token.token,
        },
        data: dataoforg,
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Organisation Deleted Successfully"
            : "Error Deleting Organisation"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  return (
    <>
      <div className="container">
        {spinner ? <Spinner></Spinner> : <></>}
        <div className="centerHead">
          <h3>Organisation Creation</h3>
          <hr></hr>
        </div>
        <div className="container">&nbsp;</div>
        <div className="row">
          <div className="col-md-3">
            <h6 className="centerHead">
              <u>List of Organisations</u>
            </h6>
            <ListGroup as="ul">
              {orgData.map((org, index) => {
                return (
                  <ListGroup.Item
                    as="li"
                    className="pointer"
                    key={index}
                    active={org.org_name === dataoforg.org_name ? true : false}
                    onClick={() => setdataoforg(org)}
                  >
                    {org.org_name}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>

          <div className="col-md-1">
            <div className="vll"></div>
          </div>

          <div className="col-md-3">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Organisation Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataoforg.org_name}
                  disabled={disable}
                  placeholder="Enter Organisation Name"
                  onChange={(e) => (dataoforg.org_name = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>OrgID</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dataoforg.org_id}
                  disabled={true}
                  placeholder="OrgID"
                />
              </Form.Group>
            </Form>
            <Button
              variant="primary"
              onClick={() => setDisable(!disable)}
              type="Edit"
            >
              {disable ? <>Edit</> : <>Cancel</>}
            </Button>
            &nbsp;
            <Button
              variant="primary"
              disabled={disable}
              type="button"
              onClick={() => {
                updateOrg();
                setspinner(true);
              }}
            >
              Update
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="danger"
              disabled={disable}
              type="delete"
              onClick={() => {
                deleteOrg();
                setspinner(true);
              }}
            >
              Delete
            </Button>
          </div>

          <div className="col-md-1">
            <div className="vlr"></div>
          </div>

          <div className="col-md-4">
            <h6 className="centerHead">
              <u>create User</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Organisation Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Organisation Name"
                  onChange={(e) => (neworg.org_name = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>OrgID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="OrgID"
                  onChange={(e) => (neworg.org_id = e.target.value)}
                />
              </Form.Group>
              <div className="row text-center">
                <div className="col">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      createOrg();
                      setspinner(true);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
