import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { Helmet } from "react-helmet";

import Navbar from "./Components/Navbar/Navbar";
import CardView from "./Components/Pages/CardView";
import OrgCreation from "./Components/Pages/OrgCreation";
import UserCreation from "./Components/Pages/UserCreation";
import ProjectCreation from "./Components/Pages/ProjectCreation";
import AdminCreation from "./Components/Pages/AdminCreation";
import Tickets from "./Components/Pages/Tickets";
import Bills from "./Components/Pages/Bills";
import Login from "./Components/Login/Login";

function App() {
  var token = JSON.parse(localStorage.getItem("login"));

  const [isloggedin, setisloggedin] = useState(false);
  const [adminData, setAdminData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  // const [authdata, setauthdata] = useState("");
  // let logged_in_admin_id;

  useEffect(() => {
    if (localStorage.getItem("login") != null) {
      var decoded = jwt_decode(token.token);

      // logged_in_admin_id = decoded.data[0].admin_id;
      setisloggedin(true);
    }
    getAdminData();
    getOrgData();
    getUserData();
    getProjectData();
    getTicketData();
  }, [isloggedin]);

  const getAdminData = async () => {
    if (isloggedin) {
      let url =
        "https://" + process.env.REACT_APP_SERVER + "/adminget/view-admin";
      const response = await axios
        .get(url, {
          headers: {
            "auth-token": token.token,
          },
        })
        .then((h) => {
          // console.log("response", h.data);
          setAdminData(h.data);
        });
    }
  };

  const getOrgData = async () => {
    if (isloggedin) {
      let url =
        "https://" + process.env.REACT_APP_SERVER + "/adminget/view-orgs";
      const response = await axios
        .get(url, {
          headers: {
            "auth-token": token.token,
          },
        })
        .then((h) => {
          // console.log("response", h.data);
          setOrgData(h.data);
        });
    }
  };

  const getUserData = async () => {
    if (isloggedin) {
      let url =
        "https://" + process.env.REACT_APP_SERVER + "/adminget/view-users";
      const response = await axios
        .get(url, {
          headers: {
            "auth-token": token.token,
          },
        })
        .then((h) => {
          // console.log("response", h.data);
          setUserData(h.data);
        });
    }
  };

  const getProjectData = async () => {
    if (isloggedin) {
      let url =
        "https://" + process.env.REACT_APP_SERVER + "/adminget/view-projects";
      const response = await axios
        .get(url, {
          headers: {
            "auth-token": token.token,
          },
        })
        .then((h) => {
          // console.log("response", h.data);
          setProjectData(h.data);
        });
    }
  };

  const getTicketData = async () => {
    if (isloggedin) {
      let url =
        "https://" + process.env.REACT_APP_SERVER + "/adminget/view-tickets";
      const response = await axios
        .get(url, {
          headers: {
            "auth-token": token.token,
          },
        })
        .then((h) => {
          // console.log("response", h.data);
          setTicketData(h.data);
        });
    }
  };

  return (
    <div className="AppPage">
      <Helmet>
        <title>Blue Knife Admin Portal</title>
      </Helmet>
      <Router>
        {isloggedin ? <Navbar /> : <></>}
        {isloggedin ? <div class="container">&nbsp;</div> : <></>}
        {isloggedin ? <CardView /> : <></>}
        {isloggedin ? <div class="container">&nbsp;</div> : <></>}
        {isloggedin ? <hr></hr> : <></>}
        {isloggedin ? <div class="container">&nbsp;</div> : <></>}
        <Routes>
          <Route path="/*" exact element={isloggedin ? <></> : <Login />} />
          <Route
            path="/ac"
            element={
              isloggedin ? (
                adminData ? (
                  <AdminCreation adminData={adminData} />
                ) : (
                  <></>
                )
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/oc"
            element={
              isloggedin ? (
                orgData ? (
                  <OrgCreation orgData={orgData} />
                ) : (
                  <></>
                )
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/uc"
            element={
              isloggedin ? (
                <UserCreation orgData={orgData} userData={userData} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pc"
            element={
              isloggedin ? (
                <ProjectCreation projectData={projectData} orgData={orgData} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/tickets"
            element={
              isloggedin ? (
                <Tickets
                  projectData={projectData}
                  orgData={orgData}
                  ticketData={ticketData}
                />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/bills"
            element={
              isloggedin ? (
                <Bills orgData={orgData} adminData={adminData} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/login"
            element={
              !isloggedin ? (
                <Login />
              ) : (
                <>
                  <p class="text-center">Already logged in</p>
                </>
              )
            }
          />
        </Routes>
      </Router>
      {isloggedin ? <div class="container">&nbsp;</div> : <></>}
    </div>
  );
}

export default App;
