import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../css/master.css";
import Spinner from "../Spinner/Spinner";

export default function Bills({ orgData, adminData }) {
  var token = JSON.parse(localStorage.getItem("login"));

  const [disable, setDisable] = useState(true);
  const [disableProject, setDisableProject] = useState(true);
  const [disableInvoice, setDisableInvoice] = useState(true);
  const [disableCreate, setDisableCreate] = useState(true);
  const [spinner, setspinner] = useState(false);

  const [newinvoice, setnewinvoice] = useState({});
  const [projectData, setProjectData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [orgname, setorgname] = useState("");
  const [projectname, setprojectname] = useState("");
  const [adminEid, setadminEid] = useState();
  const [invoice, setInvoice] = useState({});

  let logged_in_admin_eid;

  useEffect(() => {
    if (adminEid === null) {
      async function getAdminEid() {
        if (localStorage.getItem("login") != null) {
          var decoded = jwt_decode(token.token);
          logged_in_admin_eid = adminData.find(
            (r) => r.admin_id === decoded.data[0].admin_id
          ).admin_eid;
          setadminEid(logged_in_admin_eid);
          // window.alert(decoded.data[0].admin_id);
          newinvoice.issued_by = logged_in_admin_eid;
        }
      }
      getAdminEid();
    }
  }, [adminEid]);

  const getProjectData = async (datum) => {
    let url =
      "https://" +
      process.env.REACT_APP_SERVER +
      "/adminget/view-projects-by-org/" +
      datum;
    const response = await axios
      .get(url, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((h) => {
        // console.log("response", h.data);
        setProjectData(h.data);
      });
  };

  const getInvoiceData = async (datum) => {
    let url =
      "https://" +
      process.env.REACT_APP_SERVER +
      "/adminget/view-invoice-by-project/" +
      datum;
    const response = await axios
      .get(url, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((h) => {
        // console.log("response", h.data);
        setInvoiceData(h.data);
      });
  };

  const updateInvoice = async () => {
    let datatoupdate = {
      invoice_number: invoice.invoice_number,
      status: invoice.bill_status,
      details: invoice.invoice_details,
      description: invoice.invoice_description,
      deliverables: invoice.invoice_deliverables,
      invoice_amount: invoice.invoice_amount,
    };
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminput/edit-invoice";
    await axios
      .put(url, datatoupdate, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Invoice Updated Successfully"
            : "Error Updating Invoice"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const createInvoice = async () => {
    let url =
      "https://" + process.env.REACT_APP_SERVER + "/adminpost/create-invoice";
    await axios
      .post(url, newinvoice, {
        headers: {
          "auth-token": token.token,
        },
      })
      .then((r) => {
        window.alert(
          r.status == 200
            ? "Invoice Generated Successfully"
            : "Error Generating Invoice"
        );
      });
    setspinner(false);
    window.location.reload();
  };

  const getOrgId = async (datum) => {
    console.log(adminEid);
    let theOrg = orgData.find((r) => r.org_name === datum).org_id;
    getProjectData(theOrg);
    setorgname(datum);
  };

  const getProjectId = async (datum) => {
    let theProject = projectData.find(
      (r) => r.project_name === datum
    ).project_id;
    getInvoiceData(theProject);
    setprojectname(datum);
  };

  const getInvoiceId = async (datum) => {
    let theInvoice = invoiceData.find((r) => r.invoice_number === datum);
    setInvoice(theInvoice);
  };

  return (
    <>
      <div className="container">
        {spinner ? <Spinner></Spinner> : <></>}
        <div class="centerHead">
          <h3>Bills</h3>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-md-2 text-center">
            <h6 class="centerHead">
              <u>View/Update Bill</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    e.target.value === "selectorg"
                      ? setDisableProject(true)
                      : setDisableProject(false);
                    getOrgId(e.target.value);
                  }}
                >
                  <option value="selectorg">Select Organisation</option>
                  {orgData.map((org, index) => {
                    return (
                      <option key={index} value={org.org_name}>
                        {org.org_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Select
                  aria-label="Default select example"
                  disabled={disableProject}
                  onChange={(e) => {
                    e.target.value === "selectproject"
                      ? setDisableInvoice(true)
                      : setDisableInvoice(false);
                    getProjectId(e.target.value);
                  }}
                >
                  <option value="selectproject">Select Project</option>
                  {projectData.map((project, index) => {
                    return (
                      <option key={index} value={project.project_name}>
                        {project.project_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Select
                  aria-label="Default select example"
                  disabled={disableInvoice}
                  onChange={(e) => getInvoiceId(e.target.value)}
                >
                  <option value="selectproject">Select Invoice Number</option>
                  {invoiceData.map((invoicedd, index) => {
                    return (
                      <option key={index} value={invoicedd.invoice_number}>
                        {invoicedd.invoice_number}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Form>
            <div className="legend">
              <p>*Copy if needed: ₹</p>
              <p>
                *Invoice format to follow: Item Name - Units - Unit Price -
                Discount - Total
              </p>
            </div>
          </div>

          <div class="col-md-1">
            <div class="vll"></div>
          </div>

          <div className="col-md-4">
            <div className="centerText">
              <b>{orgname}</b>
            </div>
            <div className="centerText">
              <b>{projectname}</b>
            </div>
            <div className="centerText">
              <b>{invoice.invoice_number}</b>
            </div>
            <div className="centerText">
              <b>{invoice.invoice_date}</b>
            </div>
            <hr />
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Amount&nbsp;&nbsp;&nbsp;&nbsp;₹</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      defaultValue={invoice.invoice_amount}
                      type="text"
                      disabled={disable}
                      placeholder="Enter Amount"
                      onChange={(e) =>
                        (invoice.invoice_amount = parseFloat(e.target.value))
                      }
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Select
                  aria-label="Default select example"
                  disabled={disable}
                  value={invoice.bill_status}
                  onChange={(e) => {
                    let nd = {};
                    for (let x in invoice) {
                      if (x == "bill_status") {
                        nd["bill_status"] = e.target.value;
                      } else {
                        nd[x] = invoice[x];
                      }
                    }
                    setInvoice(nd);
                  }}
                >
                  <option>Select Status</option>
                  <option value="paid">Paid</option>
                  <option value="unpaid">Not Paid</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Invoice Description</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      as="textarea"
                      defaultValue={invoice.invoice_description}
                      disabled={disable}
                      rows={2}
                      placeholder="Details about the invoice."
                      onChange={(e) =>
                        (invoice.invoice_description = e.target.value)
                      }
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Invoice Details</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      as="textarea"
                      defaultValue={invoice.invoice_details}
                      disabled={disable}
                      rows={3}
                      placeholder="Comma Serparated Values with convention: Item Name - Units - Unit Price - Discount - Total. NA (case sensitive) for blank values."
                      onChange={(e) =>
                        (invoice.invoice_details = e.target.value)
                      }
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Deliverables</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      as="textarea"
                      defaultValue={invoice.invoice_deliverables}
                      disabled={disable}
                      rows={2}
                      placeholder="Deliverables (URL, Product, Credentials, etc. PLEASE TYPE N/A (CASE SENSETIVE) FOR NO DELIVERABLES"
                      onChange={(e) =>
                        (invoice.invoice_deliverables = e.target.value)
                      }
                    />
                  </div>
                </div>
              </Form.Group>
            </Form>
            <div className="text-center">
              <Button
                variant="primary"
                onClick={() => setDisable(!disable)}
                type="Edit"
              >
                {disable ? <>Edit</> : <>Cancel</>}
              </Button>
              &nbsp;
              <Button
                variant="primary"
                disabled={disable}
                type="button"
                onClick={() => {
                  updateInvoice();
                  setspinner(true);
                }}
              >
                Update
              </Button>
            </div>
          </div>

          <div class="col-md-1">
            <div class="vll"></div>
          </div>

          <div className="col-md-4">
            <h6 class="centerHead">
              <u>create Bill</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    e.target.value === "selectorg"
                      ? setDisableCreate(true)
                      : setDisableCreate(false);
                    getOrgId(e.target.value);
                  }}
                >
                  <option value="selectorg">Select Organisation</option>
                  {orgData.map((org, index) => {
                    return (
                      <option key={index} value={org.org_name}>
                        {org.org_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Select
                  aria-label="Default select example"
                  disabled={disableCreate}
                  onChange={(e) => {
                    newinvoice.project_name = e.target.value;
                  }}
                >
                  <option>Select Project</option>
                  {projectData.map((project, index) => {
                    return (
                      <option key={index} value={project.project_name}>
                        {project.project_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Invoice Number</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      type="text"
                      placeholder="Invoice Number"
                      disabled={disableCreate}
                      onChange={(e) => {
                        newinvoice.invoice_number = e.target.value;
                      }}
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Date</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      type="text"
                      placeholder="DD-MM-YYYY"
                      disabled={disableCreate}
                      onChange={(e) => {
                        newinvoice.invoice_date = e.target.value;
                      }}
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Amount</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      type="text"
                      placeholder="Enter Amount"
                      disabled={disableCreate}
                      onChange={(e) => {
                        newinvoice.invoice_amount = parseInt(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Invoice Description</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      as="textarea"
                      rows={2}
                      placeholder="Details about the invoice."
                      disabled={disableCreate}
                      onChange={(e) => {
                        newinvoice.description = e.target.value;
                      }}
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Invoice Details</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Comma Serparated Values with convention: Item Name - Units - Unit Price - Discount - Total. NA (case sensitive) for blank values."
                      disabled={disableCreate}
                      onChange={(e) => {
                        newinvoice.details = e.target.value;
                      }}
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Form.Label>Deliverables</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      as="textarea"
                      rows={2}
                      placeholder="Deliverables (URL, Product, Credentials, etc. PLEASE TYPE N/A (CASE SENSETIVE) FOR NO DELIVERABLES"
                      disabled={disableCreate}
                      onChange={(e) => {
                        newinvoice.deliverables = e.target.value;
                      }}
                    />
                  </div>
                </div>
              </Form.Group>

              <div className="text-center">
                <Button
                  disabled={disableCreate}
                  type="button"
                  onClick={() => {
                    createInvoice();
                    setspinner(true);
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
